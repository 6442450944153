<script>
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import isNull from 'lodash/isNull';

import { toRefs } from 'vue';
import moment from 'moment-timezone';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  MuiAlgoliaSelect,
  MuiSelect,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { MuiDatePicker } from '@emobg/vue-base';
import { DATE_FORMAT, DATE_FORMAT_KEYS } from '@emobg/web-utils';
import {
  initialDatesValidation,
  useAvailabilityDates,
} from '@domains/Carsharing/Availability/composable/AvailabilityDates';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton, DeleteButton, GenericModalComponent } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { DATE_INPUT_VALIDATIONS } from '@/utils';
import RemoveVehicleAllocationFormComponent from './RemoveVehicleAllocationForm/RemoveVehicleAllocationFormComponent';

export default {
  name: 'VehicleAllocationFormComponent',
  components: {
    CancelButton,
    MuiAlgoliaSelect,
    MuiSelect,
    MuiDatePicker,
    MuiValidationWrapper,
    DeleteButton,
    GenericModalComponent,
    RemoveVehicleAllocationFormComponent,
  },
  directives: {
    Validate,
  },
  props: {
    hideVehicle: {
      type: Boolean,
      default: false,
    },
    vehicleAllocation: {
      type: Object,
      default: null,
    },
    initial: {
      type: Object,
      default: null,
    },
    closeModal: {
      type: Function,
      default: () => {},
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const { vehicleAllocation, initial } = toRefs(props);
    let startDefault;
    let endDefault;

    if (vehicleAllocation.value) {
      startDefault = moment(vehicleAllocation.value.start);
      endDefault = moment(vehicleAllocation.value.end);
    } else if (initial.value) {
      const validatedDates = initialDatesValidation(initial.value.start, initial.value.end);
      startDefault = validatedDates.start;
      endDefault = validatedDates.end;
    } else {
      startDefault = moment().add(1, 'hour');
      endDefault = moment('2050-12-31 00:00:00');
    }

    const initialAvailabilityDatesData = {
      start: startDefault,
      end: endDefault,
      minStartDefault: () => moment(),
      entity: vehicleAllocation.value,
    };

    const {
      start,
      end,
      isEntityFinished,
      isEntityStarted,
      setStart,
      setEnd,
    } = useAvailabilityDates(initialAvailabilityDatesData);

    return {
      start,
      end,
      isEntityFinished,
      isEntityStarted,
      setStart,
      setEnd,
    };
  },
  data() {
    return {
      inputs: {
        vehicle_uuid: null,
        city_uuid: null,
        location_uuid: null,
      },
      uuid: null,
      isRemoveModalVisible: false,
      isFormValid: false,
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
    ]),
    title() {
      const element = 'vehicle allocation';
      return this.vehicleAllocation
        ? this.$t('Common.Actions.edit_element', { element })
        : this.$t('Common.Actions.create_element', { element });
    },
    dataToSend() {
      return {
        ...this.inputs,
        start: this.start && this.start.format(DATE_FORMAT.defaultExtended),
        end: this.end && this.end.format(DATE_FORMAT.defaultExtended),
      };
    },
    areAllRequiredFieldsFilled() {
      return this.inputs.vehicle_uuid && this.inputs.city_uuid && this.inputs.location_uuid && this.isFormValid;
    },
    ...mapState(DOMAINS_MODEL.carsharing.availability.vehicleAllocations.detail, {
      vehicleAllocationStatus: state => state.STATUS,
      vehicleAllocationData: state => state.data,
      vehicleAllocationErrors: state => state.error,
    }),
    ...mapState(DOMAINS_MODEL.carsharing.cities, {
      citiesData: state => state.data,
    }),
  },
  watch: {
    'inputs.city_uuid': {
      handler(current, previous) {
        if (previous) {
          this.inputs.location_uuid = null;
        }
      },
      deep: true,
    },
  },
  created() {
    this.DATE_FORMAT_KEYS = DATE_FORMAT_KEYS;
    this.DATE_INPUT_VALIDATIONS = DATE_INPUT_VALIDATIONS;
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.uuid = get(this.vehicleAllocation, 'uuid') || get(this.initial, 'uuid');
    this.inputs.vehicle_uuid = get(this.vehicleAllocation, 'vehicle_uuid') || get(this.initial, 'vehicle_uuid');
    this.inputs.location_uuid = get(this.vehicleAllocation, 'location_uuid') || get(this.initial, 'location_uuid');
    this.inputs.city_uuid = get(this.vehicleAllocation, 'city_uuid') || get(this.initial, 'city_uuid');
  },
  methods: {
    upperFirst,
    isNull,
    async createVehicleAllocation() {
      await this.addVehicleLocation(this.dataToSend);
      this.afterRequest();
    },
    async editVehicleAllocation() {
      await this.updateVehicleLocation({
        uuid: this.uuid,
        vehicleLocation: this.dataToSend,
      });
      this.afterRequest();
    },
    afterRequest() {
      if (this.vehicleAllocationStatus.LOADED) {
        this.closeModal();
        this.callback(this.vehicleAllocationData);
      }
    },
    onSuccessRemoveCallback() {
      this.afterRequest();
      this.closeModal();
    },
    closeRemoveModal() {
      this.isRemoveModalVisible = false;
    },
    ...mapActions(DOMAINS_MODEL.carsharing.availability.vehicleAllocations.detail, [
      'addVehicleLocation',
      'updateVehicleLocation',
    ]),
  },
};
</script>

<template>
  <div
    class="VehicleAllocationFormComponent"
    data-test-id="vehicle_allocation-form"
  >
    <GenericModalComponent
      v-if="!isRemoveModalVisible"
      v-bind="$attrs"
      :title="title"
      data-test-id="modal"
    >
      <template slot="body">
        <MuiValidationWrapper
          class="row"
          @areAllValid="isValid => isFormValid = isValid"
        >
          <div
            v-if="!hideVehicle"
            class="col-12 mb-4"
          >
            <MuiAlgoliaSelect
              v-model="inputs.vehicle_uuid"
              :filters="`${getOperatorFilter({ index: ALGOLIA_INDEXES.vehicles })} AND non_connected:0`"
              :title="result => `${result.license_plate} - ${result.vehicle_brand} ${result.vehicle_model}`"
              :label="upperFirst($t('Common.Business.vehicle')) + '*'"
              :disabled="!isNull(vehicleAllocation)"
              :placeholder="$t('Common.Actions.select_element', {element: $t('Common.Business.vehicle')})"
              :index="ALGOLIA_INDEXES.vehicles"
              path-value="uuid"
              class="w-100"
              name="vehicle_uuid"
              data-test-id="vehicles-select"
            />
          </div>
          <div class="col-12 col-sm-6 mb-4">
            <MuiSelect
              v-model="inputs.city_uuid"
              :disabled="isEntityStarted"
              :options="citiesData"
              class="w-100"
              label="City*"
              name="city_uuid"
              placeholder="Select city"
              option-value="uuid"
              option-label="name"
              data-test-id="cities-select"
            />
          </div>
          <div class="col-12 col-sm-6 mb-4">
            <MuiAlgoliaSelect
              v-model="inputs.location_uuid"
              :disabled="isEntityStarted || !inputs.city_uuid"
              :filters="`${getOperatorFilter()} AND city_uuid:${inputs.city_uuid}`"
              :index="ALGOLIA_INDEXES.locations"
              :title="(result) => `${result.name}`"
              label="Location*"
              name="location_uuid"
              class="w-100"
              path-value="uuid"
              placeholder="Select location"
              data-test-id="locations-select"
            />
          </div>
          <div class="col-12 col-sm-6 mb-4 mb-sm-0">
            <MuiDatePicker
              v-validate="{
                isRequired: true,
                isValidDate: DATE_INPUT_VALIDATIONS.validDate,
              }"
              :date="start"
              :date-format-key="DATE_FORMAT_KEYS.defaultExtended"
              :disabled="isEntityStarted"
              class="w-100"
              label="Start date*"
              data-test-id="start_date-input"
              @update:date="setStart"
            />
          </div>
          <div class="col-12 col-sm-6">
            <MuiDatePicker
              v-validate="{
                isRequired: true,
                isValidDate: DATE_INPUT_VALIDATIONS.validDate,
              }"
              :date="end"
              :date-format-key="DATE_FORMAT_KEYS.defaultExtended"
              :disabled="isEntityFinished"
              class="w-100"
              label="End date*"
              data-test-id="end_date-input"
              @update:date="setEnd"
            />
          </div>
        </MuiValidationWrapper>
      </template>
      <template slot="footer">
        <div class="d-flex flex-fill align-content-center justify-content-between">
          <div class="d-flex justify-content-center">
            <DeleteButton
              v-if="vehicleAllocation && !isEntityStarted"
              data-test-id="delete-button"
              @click="isRemoveModalVisible = true"
            />
          </div>

          <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
            <div class="d-flex justify-content-center">
              <CancelButton
                data-test-id="close-modal"
                @click="closeModal"
              />
            </div>

            <div class="d-flex justify-content-center">
              <ui-button
                :disabled="!areAllRequiredFieldsFilled"
                :loading="vehicleAllocationStatus.LOADING"
                class="wmin-initial"
                data-test-id="edit_create-button"
                @clickbutton="vehicleAllocation ? editVehicleAllocation() : createVehicleAllocation()"
              >
                {{ vehicleAllocation
                  ? $t('Common.Actions.save')
                  : $t('Common.Actions.create')
                }}
              </ui-button>
            </div>
          </div>
        </div>
      </template>
    </GenericModalComponent>
    <RemoveVehicleAllocationFormComponent
      v-if="isRemoveModalVisible"
      :header="null"
      :vehicle-allocation="vehicleAllocation"
      :callback="onSuccessRemoveCallback"
      :close-modal="closeRemoveModal"
      data-test-id="vehicle_allocation-form"
    />
  </div>
</template>
