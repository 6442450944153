import {
  computed,
  ref,
} from 'vue';
import get from 'lodash/get';
import moment from 'moment-timezone';

export function initialDatesValidation(start, end) {
  let startDate = start;
  let endDate = end;

  if (startDate.isBefore(moment())) {
    startDate = moment().second(0);

    if (startDate.isAfter(endDate)) {
      endDate = moment(this.inputs.start).add(5, 'minute');
    }
  }
  return { start: startDate, end: endDate };
}
export const useAvailabilityDates = initialData => {
  const {
    // Entity can be a long distance, vehicle allocation, unavailability objects. It must contains a start and end properties.
    entity,
    start: startValue,
    // Must be a function for the case that we want to return NOW so it will not stuck in moment that was created.
    minStartDefault,
    minStart: minStartComputed,
    end: endValue,
    minEnd: minEndComputed,
  } = initialData;

  const start = ref(startValue);
  const end = ref(endValue);

  const isEntityStarted = computed(() => {
    const now = moment();
    return now.isAfter(get(entity, 'start'));
  });
  const isEntityFinished = computed(() => {
    const now = moment();
    return now.isAfter(get(entity, 'end'));
  });
  const minStart = computed(() => {
    if (minStartComputed) {
      return minStartComputed();
    }
    return isEntityStarted.value ? false : minStartDefault();
  });
  const minEnd = computed(() => {
    if (minEndComputed) {
      return minEndComputed(start.value);
    }

    // This case should not happen as the inputs should be disabled when entity is finished
    // but just in case THIS is the min end for finished ones.
    if (isEntityFinished.value) {
      return moment(entity.end).subtract(1, 'minute');
    }
    if (isEntityStarted.value) {
      return moment().add(1, 'minute');
    }

    return moment(start.value).add(1, 'minute');
  });

  function setEnd(newEnd) {
    if (!newEnd) {
      end.value = newEnd;
      return;
    }

    end.value = newEnd.isBefore(minEnd.value) ? minEnd.value : newEnd;
  }

  function setStart(newStart) {
    if (!newStart) {
      start.value = newStart;
      return;
    }

    start.value = minStart.value && newStart.isBefore(minStart.value) ? moment(minStart.value) : newStart;

    if (end.value && end.value.isSameOrBefore(start.value)) {
      setEnd(moment(start.value).add(1, 'minute'));
    }
  }

  return {
    start,
    end,
    setStart,
    setEnd,
    isEntityStarted,
    isEntityFinished,
  };
};
