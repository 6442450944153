<script>
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  DeleteModalBodyComponent,
  GenericModalComponent,
  RemoveButton,
} from '@/components';

export default {
  components: {
    CancelButton,
    DeleteModalBodyComponent,
    GenericModalComponent,
    RemoveButton,
  },
  props: {
    vehicleAllocation: {
      type: Object,
      required: true,
    },
    callback: {
      type: Function,
      default: () => {},
    },
    closeModal: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.availability.vehicleAllocations.detail, {
      vehicleAllocationStatus: state => state.STATUS,
    }),
  },
  methods: {
    async removeVehicleAllocation() {
      await this.deleteVehicleLocation(this.vehicleAllocation.uuid);
      this.closeModal();
      this.callback();
    },
    ...mapActions(DOMAINS_MODEL.carsharing.availability.vehicleAllocations.detail, [
      'deleteVehicleLocation',
    ]),
  },
};
</script>
<template>
  <GenericModalComponent
    class="DeleteVehicleAllocationForm"
    v-bind="$attrs"
    :size="SIZES.small"
    data-test-id="delete_vehicle-allocation-form"
  >
    <template slot="body">
      <DeleteModalBodyComponent
        :text="$t('Common.Questions.delete_element', { element: 'Vehicle allocation' })"
        data-test-id="delete-modal"
      />
    </template>
    <template slot="footer">
      <CancelButton
        data-test-id="cancel-button"
        @click="closeModal"
      />
      <RemoveButton
        :loading="vehicleAllocationStatus.LOADING"
        data-test-id="remove-button"
        @click="removeVehicleAllocation"
      />
    </template>
  </GenericModalComponent>
</template>
