var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "VehicleAllocationFormComponent",
      attrs: { "data-test-id": "vehicle_allocation-form" },
    },
    [
      !_vm.isRemoveModalVisible
        ? _c(
            "GenericModalComponent",
            _vm._b(
              { attrs: { title: _vm.title, "data-test-id": "modal" } },
              "GenericModalComponent",
              _vm.$attrs,
              false
            ),
            [
              _c(
                "template",
                { slot: "body" },
                [
                  _c(
                    "MuiValidationWrapper",
                    {
                      staticClass: "row",
                      on: {
                        areAllValid: (isValid) => (_vm.isFormValid = isValid),
                      },
                    },
                    [
                      !_vm.hideVehicle
                        ? _c(
                            "div",
                            { staticClass: "col-12 mb-4" },
                            [
                              _c("MuiAlgoliaSelect", {
                                staticClass: "w-100",
                                attrs: {
                                  filters: `${_vm.getOperatorFilter({
                                    index: _vm.ALGOLIA_INDEXES.vehicles,
                                  })} AND non_connected:0`,
                                  title: (result) =>
                                    `${result.license_plate} - ${result.vehicle_brand} ${result.vehicle_model}`,
                                  label:
                                    _vm.upperFirst(
                                      _vm.$t("Common.Business.vehicle")
                                    ) + "*",
                                  disabled: !_vm.isNull(_vm.vehicleAllocation),
                                  placeholder: _vm.$t(
                                    "Common.Actions.select_element",
                                    {
                                      element: _vm.$t(
                                        "Common.Business.vehicle"
                                      ),
                                    }
                                  ),
                                  index: _vm.ALGOLIA_INDEXES.vehicles,
                                  "path-value": "uuid",
                                  name: "vehicle_uuid",
                                  "data-test-id": "vehicles-select",
                                },
                                model: {
                                  value: _vm.inputs.vehicle_uuid,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.inputs, "vehicle_uuid", $$v)
                                  },
                                  expression: "inputs.vehicle_uuid",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "col-12 col-sm-6 mb-4" },
                        [
                          _c("MuiSelect", {
                            staticClass: "w-100",
                            attrs: {
                              disabled: _vm.isEntityStarted,
                              options: _vm.citiesData,
                              label: "City*",
                              name: "city_uuid",
                              placeholder: "Select city",
                              "option-value": "uuid",
                              "option-label": "name",
                              "data-test-id": "cities-select",
                            },
                            model: {
                              value: _vm.inputs.city_uuid,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "city_uuid", $$v)
                              },
                              expression: "inputs.city_uuid",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12 col-sm-6 mb-4" },
                        [
                          _c("MuiAlgoliaSelect", {
                            staticClass: "w-100",
                            attrs: {
                              disabled:
                                _vm.isEntityStarted || !_vm.inputs.city_uuid,
                              filters: `${_vm.getOperatorFilter()} AND city_uuid:${
                                _vm.inputs.city_uuid
                              }`,
                              index: _vm.ALGOLIA_INDEXES.locations,
                              title: (result) => `${result.name}`,
                              label: "Location*",
                              name: "location_uuid",
                              "path-value": "uuid",
                              placeholder: "Select location",
                              "data-test-id": "locations-select",
                            },
                            model: {
                              value: _vm.inputs.location_uuid,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "location_uuid", $$v)
                              },
                              expression: "inputs.location_uuid",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12 col-sm-6 mb-4 mb-sm-0" },
                        [
                          _c("MuiDatePicker", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                  isValidDate:
                                    _vm.DATE_INPUT_VALIDATIONS.validDate,
                                },
                                expression:
                                  "{\n              isRequired: true,\n              isValidDate: DATE_INPUT_VALIDATIONS.validDate,\n            }",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              date: _vm.start,
                              "date-format-key":
                                _vm.DATE_FORMAT_KEYS.defaultExtended,
                              disabled: _vm.isEntityStarted,
                              label: "Start date*",
                              "data-test-id": "start_date-input",
                            },
                            on: { "update:date": _vm.setStart },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12 col-sm-6" },
                        [
                          _c("MuiDatePicker", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                  isValidDate:
                                    _vm.DATE_INPUT_VALIDATIONS.validDate,
                                },
                                expression:
                                  "{\n              isRequired: true,\n              isValidDate: DATE_INPUT_VALIDATIONS.validDate,\n            }",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              date: _vm.end,
                              "date-format-key":
                                _vm.DATE_FORMAT_KEYS.defaultExtended,
                              disabled: _vm.isEntityFinished,
                              label: "End date*",
                              "data-test-id": "end_date-input",
                            },
                            on: { "update:date": _vm.setEnd },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-fill align-content-center justify-content-between",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-center" },
                      [
                        _vm.vehicleAllocation && !_vm.isEntityStarted
                          ? _c("DeleteButton", {
                              attrs: { "data-test-id": "delete-button" },
                              on: {
                                click: function ($event) {
                                  _vm.isRemoveModalVisible = true
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-center justify-content-sm-end align-items-center",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-center" },
                          [
                            _c("CancelButton", {
                              attrs: { "data-test-id": "close-modal" },
                              on: { click: _vm.closeModal },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-center" },
                          [
                            _c(
                              "ui-button",
                              {
                                staticClass: "wmin-initial",
                                attrs: {
                                  disabled: !_vm.areAllRequiredFieldsFilled,
                                  loading: _vm.vehicleAllocationStatus.LOADING,
                                  "data-test-id": "edit_create-button",
                                },
                                on: {
                                  clickbutton: function ($event) {
                                    _vm.vehicleAllocation
                                      ? _vm.editVehicleAllocation()
                                      : _vm.createVehicleAllocation()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.vehicleAllocation
                                        ? _vm.$t("Common.Actions.save")
                                        : _vm.$t("Common.Actions.create")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.isRemoveModalVisible
        ? _c("RemoveVehicleAllocationFormComponent", {
            attrs: {
              header: null,
              "vehicle-allocation": _vm.vehicleAllocation,
              callback: _vm.onSuccessRemoveCallback,
              "close-modal": _vm.closeRemoveModal,
              "data-test-id": "vehicle_allocation-form",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }