var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._b(
      {
        staticClass: "DeleteVehicleAllocationForm",
        attrs: {
          size: _vm.SIZES.small,
          "data-test-id": "delete_vehicle-allocation-form",
        },
      },
      "GenericModalComponent",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c("DeleteModalBodyComponent", {
            attrs: {
              text: _vm.$t("Common.Questions.delete_element", {
                element: "Vehicle allocation",
              }),
              "data-test-id": "delete-modal",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("CancelButton", {
            attrs: { "data-test-id": "cancel-button" },
            on: { click: _vm.closeModal },
          }),
          _c("RemoveButton", {
            attrs: {
              loading: _vm.vehicleAllocationStatus.LOADING,
              "data-test-id": "remove-button",
            },
            on: { click: _vm.removeVehicleAllocation },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }